
@use 'variables';

.woelkchen {
    width: 100vw;
    z-index: -10;
    position: fixed;
    top: 0;
}

iframe {
    position: absolute;
    width: 80vw;
    height: 60vh;
    z-index: 48;
    left: 50%;
    top: 22%;
    transform: translate(-50%);
}

.countdown {
    font-family: variables.$boldFont;
    text-align: center;
    align-self: center;
    color: variables.$primaryColor;
    font-size: 200px;
    line-height: 230px;
    position: relative;
    display: block;
    top: 45px;
    left: 50vw;
    transform: translateX(-50%);
    margin: auto;
}

.name {
    font-family: BeatriceDEB;
    text-align: center;
    align-self: center;
    margin: auto;
    color: variables.$primaryColor;
    font-size: 200px;
    line-height: 230px;
    position: relative;
    bottom: 0;
    left: 50vw;
    transform: translateX(-50%);
    margin: auto;
}

video{
    position: fixed;
    z-index: 1;
}

.pop-up {
    position: absolute;
    left: 50%;
    top: 18vh;
    transform: translate(-50%);
    width: 90vw;
    height: 70vh;
    background-color: white;
    z-index: 49;
    text-align: center;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.pop-up-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
}

.pop-up-close {
    background-image: url('../assets/close_button-01.png');
    width: 25px;
    position: absolute;
    right: 1vw;
    top: 1vw;
    padding: 1rem;
    z-index: 1000;

    &:hover {
        cursor: pointer;
    }
}

.weiter{
    justify-content: center;
    position: absolute;
    width: 100%;
    display: flex;
    padding: 0;
    bottom: 2%;
}

.overlay-next{
    padding: 4px 15px;
    border-radius: 30px;
    border-style: outset;
    border: 1px solid variables.$primaryColor;
    text-decoration: none;
}

.grafiken_bg{
    position:relative;
    height: 60%;
    width: 90%;
}

.pop_up_grafiken{
    width: 100%;
}

.overlay-next{
    margin: 5px;
}

#start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

#laptop_one {
    display: none;
}

#laptop_two {
    display: none;
}

#handy_one{
    display: none;
}
#handy_two{
    display: none;
}
#handy_three{
    display: none;
}
#handy_four{
    display: none;
}
#handy_five{
    display: none;
}

#handy_vr_one{
    display: none;
}
#handy_vr_two{
    display: none;
}
#handy_vr_three{
    display: none;
}
#handy_vr_four{
    display: none;
}
#handy_vr_five{
    display: none;
}

.einfuehrung{
    font-family: BeatriceDEB;
    font-size: 45px;
    line-height: 50px;
    margin: 20px;
    margin-top: 25%;
}

.cookies-container {
    overflow: scroll;
    height: 80%;
    margin: 2vh 5vw;
}

.pop-up-close {
    display: none;
}

.cookies-text{
    overflow: scroll;
    margin: 3%;
    font-size: 8px;
    scrollbar-color: variables.$primaryColor white;
}


#start, #laptop_one, #laptop_two, #handy_one, #handy_two, #handy_three, #handy_four, #handy_five, #handy_vr_one, #handy_vr_two,
#handy_vr_three, #handy_vr_four, #handy_vr_five {
    display: none;
}

@media (min-width: variables.$small) {
    .cookies-text {
     font-size: 10px;
    }
    .cookies-container {
        height: 75%;
    }
    iframe {
        width: 80vw;
        height: 80vh;
        top: 12%;
    }
    .pop-up{
        width: 50vw;
        height: 60vh;
    }
    .grafiken_bg{
        height: 40vh;
        width: auto;
    }

    .pop-up-video-container {
        height: 65%;
    }

    .pop_up_grafiken {
        height: 100%;
        width: auto;
    }

    .weiter {
        width: 100%;
        bottom: 5%;
    }

    .einfuehrung{
        font-family: BeatriceDEB;
        font-size: 80px;
        line-height: 85px;
        margin: 20px;
        margin-top: 8%;
        height: 70%;
    }

    .pop-up-close {
        width: 2vw;
    }
}
