@use 'variables';
@use 'layout';
@use 'nav';
@use 'kollektionen';
@use 'sites';
@use 'show';

#tilt_two {
  z-index: -1;
  position: fixed;
  height: 100%;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aplus {
  margin: 0;
  z-index: 30;
  width: 80vw;
}

.timer-index {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 3;
}

    .impressum {
    font-size: variables.$regularFontSize;
}


@media (min-width: variables.$small) {
  #tilt_two {
    height: auto;
    width: 120%;
    top: -29rem;
  }

  .aplus {
    font-size: 30rem;
  }
    .impressum {
    font-size: variables.$regularFontSize;
}

}
