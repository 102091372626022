@use '../styles/_variables.scss';

.nav {
    display: none;
    position: fixed;
    align-items: flex-end;
    flex-direction: column;
    top: 0px;
    width: 100%;
    z-index: 50;

    a {
        text-decoration: none;
        padding: 0 3%;
    }

    span {
        position: relative;
        display: inline-block;
    }

    .nav-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        //box-shadow: inset 0px 200px 10px 10px rgba(255, 255, 255, 0.55);
        background: -webkit-gradient(top, bottom, rgba(255, 255, 255, 1), rgba(255,255,255,0));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255,255,255,0));
    }
}

@media (min-width: variables.$small) {
    .nav {
        flex-direction: row;
        justify-content: center;

        a {
            padding: 2rem 2rem;
        }

    .nav-bg {
        box-shadow: none;
        }
    }
}
