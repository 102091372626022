@font-face {
    font-family: BeatriceDEB;
    src: url(../fonts/BeatriceDisplayExtraBold.otf);
}

@font-face {
    font-family: BeatriceM;
    src: url(../fonts/BeatriceRegular.otf);
}


$primaryColor: #0000ff;

$regularFont: BeatriceM;
$boldFont: BeatriceDEB;
$regularFontSize: 20px;
$mediumFontSize: 32px;
$bigFontSize: 7rem;
$headingSizeSmall: 4rem;
$headingLineHeight: 98%;
$headingSizeBig: 150px;

$small: 600px;
$medium: 900px;
