@use 'variables';

.uebersicht {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 7rem;
}

.uebersicht-container {
    margin-top: 0.8rem;

    a {
        text-decoration: none;
    }

    img {
        display: none;
    }
}

.uebersicht-text {
    font-size: variables.$mediumFontSize;
    font-family: variables.$boldFont;
}


@media (min-width: variables.$small) {
    .uebersicht-container {
        img {
            z-index: -1;
            display: none;
            height: 80%;
            position: fixed;
            transform: translate(-50%);
            top: 10%;
            left: 50%;
            filter: blur(0.4rem);
            transition: 0.2s;
        }

        &:hover {
            img {
                display: block;
                transition: 0.2s;
            }
        }
    }

    .uebersicht-text {
        font-size: 4rem
    }
}



@media (min-width: variables.$medium) {
    .uebersicht-container {
        margin-top: 1.2rem;
    }

    .uebersicht-text {
        font-size: variables.$bigFontSize;
    }
}
