@use 'variables';

.video {
    z-index: -5;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.4;
    background: url("../assets/woelkchenanimationKLEIN.gif") center;
    background-position: center;
    background-size: cover;
}

.site-content {
    padding: 1.5rem 0;
    height: 400vh;

    p:last-of-type {
        position: sticky;
        top: 10vh;
    }
}

.credits{
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid variables.$primaryColor;
}

.info {
    height: auto;
}

.back {
    font-size: 18px;
    position: fixed;
    z-index: 90;
    top: 0.5rem;
    left: 0.5rem;
    padding: 4px 15px;
    border-radius: 30px;
    border-style: outset;
    border: 1px solid variables.$primaryColor;
    text-decoration: none;

    span {
        position: relative;
        display: inline-block;
    }
}

.kollektion{
    span{
        position: relative;
        display: inline-block;
    }
}

.ueberschrift {
    position: relative;
    z-index: 3;
    font-size: 2.4rem;
    font-family: variables.$boldFont;
    line-height: variables.$headingLineHeight;
    margin: 4.5rem 0 3.5rem 0;
    padding: 0.8rem;

    &:hover {
        cursor: url('../assets/pfeil_cursor@3x.svg') 25 25, pointer;
        cursor: -webkit-image-set( url('../assets/pfeil_cursor@3x.svg') 3x)25 25, pointer;
    }
}

.lastp {
    margin: 0 1rem;
}

.image {
    position: absolute;
    z-index: 5;
    width: 50 + random() * 150 + px;

    &:hover {
        cursor: url('../assets/pfeil_cursor@3x.svg') 25 25, pointer;
        cursor: -webkit-image-set( url('../assets/pfeil_cursor@3x.svg') 3x)25 25, pointer;
    }
}

.image-01 {
    width: 210px;
    top: 40vh;
    right: 5vw;
}

.image-02 {
    top: 135vh;
    width: 50 + random() * 150 + px;
}

.image-03 {
    top: 170vh;
    right: 22%;
    width: 50 + random() * 150 + px;
}

.image-04 {
    top: 204vh;
    left: 5%;
    width: 50 + random() * 150 + px;
}

.image-05 {
    top: 230vh;
    right: 5%;
    width: 50 + random() * 150 + px;
}

.image-06 {
    top: 210vh;
    left: 5%;
    width: 50 + random() * 150 + px;
}

.image-07 {
    top: 250vh;
    left: 5%;
    width: 50 + random() * 150 + px;
}

.image-08 {
    top: 290vh;
    left: 5%;
    width: 50 + random() * 150 + px;
}

.image-09 {
    top: 320vh;
    right: 5%;
    width: 50 + random() * 150 + px;
}

.image-10 {
    top: 310vh;
    left: 19%;
    width: 50 + random() * 150 + px;
}

.image-11 {
    top: 340vh;
    left: 5%;
    width: 50 + random() * 150 + px;
}

.image-12 {
    top: 380vh;
    right: 5%;
    width: 50 + random() * 150 + px;
}


.fullscreen {
    height: auto;
    width: 90vw;
}

.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    display: flex;
//    width: 90%;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    bottom: 0;
    padding: 4px 15px;
    text-align: center;

    a {
        margin-bottom: 10px;
        font-size: 18px;
        padding: 4px 15px;
        border-radius: 30px;
        border-style: outset;
        border: 1px solid variables.$primaryColor;
        text-decoration: none;
    }
}



@media (min-width: variables.$small) {
    .ueberschrift {
        font-size: variables.$headingSizeBig;
        text-align: center;
        top: 15vh;
    }
    
    .three-lines {
        top: 15vh;
    }

    .lastp {
        font-size: 60px;
        line-height: 1.17;
        width: 85vw;
        margin-left: 5%;
    }

    .site-content {
        p {
            &:first-of-type {
                margin-top: 20%;
            }
        }
    }

    .image {
        &:first-of-type {
            width: 600px;
            right: 5vw;
        }
    }

    .image-02 {
        top: 130vh;
        width: 250 + random() * 150 + px;
        left: 5vw;
    }

    .image-03 {
        top: 190vh;
        right: 22vw;
        width: 250 + random() * 150 + px;
    }

    .image-04 {
        top: 224vh;
        left: 30%;
        width: 250 + random() * 150 + px;
    }

    .image-05 {
        top: 250vh;
        right: 5%;
        width: 250 + random() * 150 + px;
    }

    .image-06 {
        top: 250vh;
        right: 22%;
        left: auto;
        width: 250 + random() * 150 + px;
    }

    .image-07 {
        top: 320vh;
        left: 30%;
        width: 250 + random() * 150 + px;
    }

    .image-08 {
        top: 340vh;
        right: 5%;
        width: 250 + random() * 150 + px;
    }

    .image-09 {
        top: 360vh;
        right: 5%;
        width: 250 + random() * 150 + px;
    }

    .image-10 {
        top: 390vh;
        left: 30%;
        width: 250 + random() * 250 + px;
    }

    .image-11 {
        top: 420vh;
        left: 15%;
        width: 250 + random() * 150 + px;
    }

    .image-12 {
        top: 450vh;
        right: 18%;
        width: 250 + random() * 250 + px;
    }
        
    .fullscreen {
        height: 95vh;
        width: auto;
    }
    
    .back {
        padding: 4px 15px;
        margin: 1.25rem 1rem;
    }

    .buttons {
        background: transparent;
        width: 96%;
        margin: 0rem 1rem;
        flex-direction: row;

        a {
            margin-bottom: none;
            margin: 2rem 0;
        }
    }
}
