@use 'variables';

* {
    color: variables.$primaryColor;
    cursor: url('../assets/plus_cursor-02@3x.svg') 25 25, pointer;
    cursor: -webkit-image-set( url('../assets/plus_cursor-02@3x.svg') 3x)25 25, pointer;

    &::selection {
        color: #fff;
    }

    &.buttons {
        color: variables.$primaryColor;
    }
}


body {
    background-color: white;   /* F2F2F0  */
    font-family: BeatriceM;
    font-size: variables.$regularFontSize;
    padding: 0px 0px;
    margin: 0;
    width: 100%;
    overflow-x: hidden;

}

